import React, { useState } from 'react'
import { getIngredient, hasIngredient, calcGramsInStarter } from './helpers'

interface DoughTableProps {
  totalGramsFlour?: number,
  doughIngredients: Array<Ingredient>,
  starterIngredients: Array<Ingredient>,
  handleSetIngredients: (newdoughIngredients: Array<Ingredient>) => void,
}

interface IngredientRow extends Ingredient {
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
}

const IngredientRow = ({ name, totalGramsFlour, gramsFromStarter, percentage, onChange }: IngredientRow) => {
  let grams
  if (totalGramsFlour && Number(percentage)) {
    grams = Math.round(totalGramsFlour * Number(percentage) / 100)
  }
  return (
    <tr>
      <td>{name}</td>
      <td><input type="number" value={percentage || ''} onChange={onChange} />%</td>
      <td>
        {grams && gramsFromStarter ? grams - gramsFromStarter : grams}g
      {gramsFromStarter ? ` (plus an additional ${gramsFromStarter}g from starter)` : null}
      </td>
    </tr>
  )
}

const DoughTable = ({ totalGramsFlour, doughIngredients, starterIngredients, handleSetIngredients, }: DoughTableProps) => {
  const [inputName, setInputName] = useState<string>('')

  const addIngredientRow = () => {
    if (!hasIngredient(doughIngredients, inputName)) {
      handleSetIngredients([...doughIngredients, {
        name: inputName,
        totalGramsFlour
      }])
      setInputName('')
    }
  }

  const modifyIngredientRow = (name: string, percentage: number) => {
    const newDoughIngredients = [...doughIngredients]
    const ingredientToModify = newDoughIngredients.find((ingredient) => name === ingredient.name)
    if (ingredientToModify) {
      ingredientToModify.percentage = percentage
    }
    handleSetIngredients(newDoughIngredients)
  }

  return (
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <td>Ingredient</td>
            <td>Percentage of total flour</td>
            <td>Weight in grams</td>
          </tr>
        </thead>
        <tbody>
          {doughIngredients.map((ingredient) => {
            const { name, percentage } = ingredient
            let gramsFromStarter

            if (hasIngredient(starterIngredients, name)) {
              const starter = getIngredient(doughIngredients, 'starter')
              const starterPercentage = starter && Number(starter.percentage) ? Number(starter.percentage) : 0
              gramsFromStarter = calcGramsInStarter(name, totalGramsFlour || 0, starterPercentage, starterIngredients)
            }
            return (
              <IngredientRow
                key={name}
                totalGramsFlour={totalGramsFlour}
                name={name}
                percentage={percentage}
                gramsFromStarter={gramsFromStarter}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement
                  modifyIngredientRow(name, Number(target.value))
                }} />
            )
          })}
        </tbody>
      </table>
      <input value={inputName} onChange={(event) => {
        setInputName(event.target.value)
      }} />
      <button disabled={inputName.trim() === '' || hasIngredient(doughIngredients, inputName)} onClick={addIngredientRow}>Add Ingredient</button>
    </React.Fragment>
  )
}

export default DoughTable