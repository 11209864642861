export const hasIngredient = (ingredients: Array<Ingredient>, inputName: string) => {
  return !!getIngredient(ingredients, inputName)
}

export const getIngredient = (ingredients: Array<Ingredient>, inputName: string): Ingredient | undefined => {
  return ingredients.find((flour) => {
    return flour.name.trim().toLowerCase() === inputName.trim().toLowerCase()
  })
}

export const calcGramsInStarter = (name: string, totalGramsFlour: number, starterPercentage: number = 0, starterIngredients: Array<Ingredient>) => {
  const totalStarterWeight = starterIngredients.map((ingredient) => ingredient.grams || 0).reduce((a, b) => a + b)
  const ingredientInStarter = getIngredient(starterIngredients, name)
  const ratio = ingredientInStarter && ingredientInStarter.grams ? ingredientInStarter.grams / totalStarterWeight : 0
  return Math.round(totalGramsFlour * starterPercentage / 100 * ratio)
}

// Minimum percentage in dough based on starter contents
export const calcMinPercentage = (name: string, totalGramsFlour: number, starterPercentage: number = 0, starterIngredients: Array<Ingredient>) => {
  console.log('TODO calc min percent')
}