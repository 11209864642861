import React, { useState } from 'react'
import { hasIngredient } from './helpers'

interface StarterTableProps {
  starterIngredients: Array<Ingredient>,
  handleSetIngredients: (newdoughIngredients: Array<Ingredient>) => any
}

type RowProps = {
  name: string,
  grams?: number,
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
}
const StarterIngredientRow = ({ name, grams, onChange }: RowProps) => {
  return (
    <tr>
      <td>{name}</td>
      <td><input type="number" value={grams || ''} onChange={onChange} />g</td>
    </tr>
  )
}

const StarterTable = ({ starterIngredients, handleSetIngredients }: StarterTableProps) => {
  const [inputName, setInputName] = useState<string>('')

  const addIngredientRow = () => {
    if (!hasIngredient(starterIngredients, inputName)) {
      handleSetIngredients([...starterIngredients, {
        name: inputName
      }])
      setInputName('')
    }
  }

  const handleIngredientChange = (ingredientName: string, event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const newIngredients = [...starterIngredients]
    const toModify = newIngredients.find((i => i.name === ingredientName))
    if (toModify) {
      toModify.grams = target.value ? Number(target.value) : undefined
      handleSetIngredients(newIngredients)
    }
  }

  return (
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <td>Ingredient</td>
            <td>Weight in grams</td>
          </tr>
        </thead>
        <tbody>
          {starterIngredients.map((ingredient) => {
            const { name } = ingredient
            return (
              <StarterIngredientRow key={name} {...ingredient} onChange={(e) => handleIngredientChange(name, e)} />
            )
          })}
        </tbody>
      </table>
      <input value={inputName} onChange={(event) => {
        setInputName(event.target.value)
      }} />
      <button disabled={inputName.trim() === '' || hasIngredient(starterIngredients, inputName)} onClick={addIngredientRow}>Add Ingredient</button>
    </React.Fragment>
  )
}

export default StarterTable