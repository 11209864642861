import React, { Component, useState } from 'react'
import DoughTable from './DoughTable'
import StarterTable from './StarterTable'
import { hasIngredient } from './helpers';

const BakersPercentageCalc = () => {
    const [totalGramsFlour, setTotalGramsFlour] = useState<number | undefined>(800)
    const [starterIngredients, setStarterIngredients] = useState<Array<Ingredient>>([
        { name: '💦', grams: 100 },
        { name: 'All-Purpose Flour', grams: 100 }
    ])
    const [doughIngredients, setDoughIngredients] = useState<Array<Ingredient | MultiIngredient>>([
        { name: 'Starter', percentage: 10, ingredients: starterIngredients },
        { name: '💦', percentage: 80 },
        { name: 'Salt', percentage: 2 },
        { name: 'All-Purpose Flour', percentage: 50 },
    ])

    // Need to add starter ingredients implicitly to dough ingredients.
    const handleSetStarterIngredient = (starterIngredients: Array<Ingredient>) => {
        setStarterIngredients(starterIngredients)
        starterIngredients.forEach((ingredient) => {
            if (!hasIngredient(doughIngredients, ingredient.name)) {
                const newIngredient = { name: ingredient.name } // TODO min percentage and percentage
                setDoughIngredients([...doughIngredients, newIngredient])
            } else {
                // update minPercent
            }
        })
    }

    return (
        <React.Fragment>
            <section>
                How much total flour do you want to use in grams?
            <input type='number' value={totalGramsFlour || ''} onChange={(event) => {
                    const value = event.target.value ? Number(event.target.value) : undefined
                    setTotalGramsFlour(value)
                }} />g
            </section>
            <section>
                What ingredients do you use to refresh your starter? <StarterTable starterIngredients={starterIngredients} handleSetIngredients={handleSetStarterIngredient} />
            </section>
            <section>
                Let's enter some percentages
                <DoughTable totalGramsFlour={totalGramsFlour} doughIngredients={doughIngredients} starterIngredients={starterIngredients} handleSetIngredients={setDoughIngredients} />
            </section>
        </React.Fragment>
    )
}

export default BakersPercentageCalc